#logo {
    width:100%;
    max-width: 97%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top:0vh;
    margin-left: 3vw;
    padding-top: 0;
    overflow: hidden;
}
#logo-1{
    width: 200px;
    /* max-width: 97%; */
    height: 100px;
    position:absolute;
    top:0;
    left:0;
    margin:20px; 
}