* {
    margin: 0;
    padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
 * {
    -ms-overflow-style:none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }